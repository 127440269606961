var $ = jQuery.noConflict();

import "slick-carousel/slick/slick.js";

$(function() {
    slickify();
});

function slickify() {
    $('[data-aka-image-carousel]').each(function () {
        let prevArrow = $(this).siblings('[data-aka-image-carousel-arrow-prev]');
        let nextArrow = $(this).siblings('[data-aka-image-carousel-arrow-next]');
        let slickOptions = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            speed: 500,
            cssEase: 'linear',
            adaptiveHeight: true,
            centerMode: true,
            infinite: true,
            variableWidth: true,
            initialSlide: $(this).attr('[data-aka-image-carousel-first]') || 0,
        };

        // if (prevArrow) {
        //     slickOptions.prevArrow = $(prevArrow);
        // }
        // if (nextArrow) {
        //     slickOptions.nextArrow = $(nextArrow);
        // }

        $(this).slick(slickOptions);
    });
}